import { createAsyncThunk, createSlice  } from "@reduxjs/toolkit";
import moment from "moment";

import Hds from "date-holidays";


const hd = new Hds("NZ", "AUK");
const currentYearHoliday=hd.getHolidays(2025);
const h26 = hd.getHolidays(2026);
const h27 = hd.getHolidays(2027);
const h28 = hd.getHolidays(2028);
const h29 = hd.getHolidays(2029);

//组成一个新的假期数组,用于判断下一个公共假日的时间
const holiday = [...currentYearHoliday, ...h26,...h27,...h28,...h29];

//不是公共假期的话返回false, 是公共假期的话返回一个数组
//const isHolidy=hd.isHoliday(new Date('2023-12-27 00:00:00 GMT+0000'));
//const isHolidy=hd.isHoliday(moment('2023-12-24'));
//console.log('isHoliday',isHolidy.length>0);


  //判断即将到来的下一个公共假期,放到一个新的数组中.
  const newholiday = holiday.filter((item) => {
    return moment().isBefore(moment(item.date));
  });
  //console.log("sliceHoliday",newholiday[0]);
 // console.log(moment(newholiday[0].date).startOf("day").fromNow());
 //console.log('newholiday',newholiday[0]);
 
//异步切换数据
export const loadAsync=createAsyncThunk('changeYear',async(state)=>{
 
  //  let newArr=hd.getHolidays(state).map((item)=>{
  //   return {date:item.date,name:item.name}
  //  })
  //  console.log(`newArr`, newArr);
  //  return newArr

  return hd.getHolidays(state) 

})



const sliceHoliday=createSlice({
    name:'holiday',
    initialState:{
      isloading:false,  //刚开始不加载,因为需要首次渲染当前年
        //数组的第一个元素即为即将到来的第一个假期
        comingHoliday:newholiday[0],
        //现在(当前年)的假期数组
        currentHoliday:currentYearHoliday,
        //判断当天是否为公共假日,不是公共假期的话返回false,是公共假期的话返回一个数组
        isHoliday:hd.isHoliday(moment()).length>0
    },
    extraReducers:(builder)=>{
      builder.addCase(loadAsync.pending,state=>{
        state.isloading=true;
      })
      .addCase(loadAsync.fulfilled,(state,action)=>{
        //state.currentHoliday=action.payload;
      
        state.currentHoliday=null;
        state.currentHoliday=action.payload
        state.isloading=false;
      })
      .addCase(loadAsync.rejected,(state)=>{

      })
    }

    
    
})



export const { testF } = sliceHoliday.actions
export default sliceHoliday.reducer